import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shippingCard: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1, 3, 2),
    },
    shippingHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    shippingHeaderLeft: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
    },
    shippingHeaderRight: {
      display: 'flex',
      alignItems: 'center',
      height: '80%',
      '& .totalPriceContainer': {
        '& .totalPriceWrapper': {
          backgroundColor: theme.palette.background.default,
          borderRadius: '5px',
        },
      },
    },
    kiloGram: {
      display: 'flex',
      height: '80%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.default,
      borderRadius: '5px',
      width: '120px',
      textAlign: 'center',
    },
    orderIsReadyWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.grey[300],
    },
    formControlStyle: {
      flexGrow: 4,
      flexShrink: 4,
      flexBasis: 4,
      minWidth: '20%',
      margin: theme.spacing(1),
    },
  }),
);

export default useStyles;
