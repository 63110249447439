import { ShipmentCreateWithLineItemDto } from './dto/shipmentCreateWithLineItem.dto';
import { AxiosResponse } from 'axios';
import { backendUri } from '../../shared/helper/env/helper';
import { ShipmentUpdateDto } from './dto/shipmentUpdate.dto';
import { ShipmentCreateByOrderDto } from './dto/shipmentCreateByOrder.dto';
import { ShipmentCreateLabelDto } from './dto/shipmentCreateLabel.dto';

import { OrderDto } from '../order-service/order.service';
import useSWR from 'swr';
import { ShipmentUpdateResponseDto } from './dto/shipmentUpdateResponse.dto';
import { testLabel } from './test-label';
import client, { IUseArrayResponse, SchemaType, api } from '../../shared/client';
import { OrderType, ShipmentState, ShipmentType } from '../../shared/backend';
import { IActivityContext, activityContextToHeader } from '../activity-service/activity.service';

export interface ShipmentDto extends SchemaType<'ShipmentDto'> {
  order?: OrderDto;
}
export interface ShipmentReturnDto extends SchemaType<'ShipmentReturnDto'> {}
export interface ShipmentWithLabelDto extends SchemaType<'ShipmentWithLabelDto'> {}
export interface ShipmentWithTourDto extends SchemaType<'ShipmentWithTourDto'> {}

interface GetShipmentsSearchQuery {
  limit?: number;
  offset?: number;
  search?: string;
  processIds?: number[];
  processTypeIds?: number[];
  shipmentStates?: ShipmentState[];
  useExtendedSearchOverAllFields?: boolean;
}

interface GetShipmentSummaryCSVQuery {
  processIds: number[];
  toDate: string;
  fromDate: string;
}

interface GetRetoureShipmentsCSVQuery {
  processIds: number[];
  toDate: string;
  fromDate: string;
  shipmentStates?: string[];
}

export enum LabelFormat {
  PNG = 'png',
  PDF = 'pdf',
}
interface ICreateLabelQuery {
  format?: LabelFormat;
}

interface IWeightByWarehouse {
  warehouse: string;
  weight: number;
}

interface IBatchBarcodeSummaryDto {
  shipments: {
    shipmentId: number;
    foreignOutboundTrackingId: string;
    state: ShipmentState;
    orderId: number;
    isBlocked?: boolean;
  }[];
  weight: number;
}

export namespace ShipmentService {
  /** =============================
   *  POST-ROUTES
   *  =============================
   */

  export async function createShipmentByOrder(
    orderId: number | undefined,
    body: ShipmentCreateWithLineItemDto,
  ): Promise<ShipmentCreateByOrderDto> {
    const res = await client.post<ShipmentCreateWithLineItemDto, AxiosResponse<ShipmentCreateByOrderDto>>(
      `${backendUri}/order/${orderId}/shipments`,
      body,
    );

    return res.data;
  }

  export async function createRetoureShipment(
    shipmentId: number,
    body: ShipmentCreateWithLineItemDto,
  ): Promise<ShipmentDto> {
    const res = await client.post<ShipmentCreateWithLineItemDto, AxiosResponse<ShipmentDto>>(
      `${backendUri}/v1/shipments/${shipmentId}/returnShipment`,
      body,
    );

    return res.data;
  }

  export async function createReturnShipmentWithLabel(orderId: number): Promise<ShipmentWithLabelDto> {
    if (
      process.env.NODE_ENV === 'development' &&
      !['0', 'false'].includes(localStorage.getItem('dev:mock-label') ?? '')
    ) {
      return { shipmentId: 12345, label: testLabel } as ShipmentWithLabelDto;
    }

    const res = await api.post<'/order/{orderId}/return-label'>(`${backendUri}/order/${orderId}/return-label`);
    return res.data;
  }

  export async function acceptReturnShipment(
    shipmentId: number,
    activityContext: IActivityContext = {},
  ): Promise<ShipmentDto> {
    const res = await client.post<{}, AxiosResponse<ShipmentDto>>(
      `${backendUri}/v1/shipments/${shipmentId}/accept`,
      undefined,
      { headers: activityContextToHeader(activityContext) },
    );
    return res.data;
  }

  export async function handleEmptyShipmentFallback(shipmentId: number): Promise<ShipmentDto> {
    const res = await client.post<{}, AxiosResponse<ShipmentDto>>(
      `${backendUri}/v1/shipments/${shipmentId}/emptyShipmentFallback`,
    );
    return res.data;
  }

  export async function createLabel(
    shipmentId: number,
    shipmentMethodId: number,
    query?: ICreateLabelQuery,
    activityContext: IActivityContext = {},
  ): Promise<string> {
    if (
      process.env.NODE_ENV === 'development' &&
      !['0', 'false'].includes(localStorage.getItem('dev:mock-label') ?? '')
    ) {
      await updateShipment(shipmentId, { state: ShipmentState.LABEL_PRINTED }, activityContext);

      return testLabel;
    }

    const res = await client.post<unknown, AxiosResponse<ShipmentCreateLabelDto>>(
      `${backendUri}/v1/shipments/${shipmentId}/label/${shipmentMethodId}`,
      {},
      {
        headers: activityContextToHeader(activityContext),
        params: {
          format: query?.format,
        },
      },
    );

    return res.data.label;
  }

  export async function addLineItemToShipment(shipmentId: number, lineItemId: number): Promise<ShipmentDto> {
    const res = await client.post<unknown, AxiosResponse<ShipmentDto>>(
      `${backendUri}/v1/shipments/${shipmentId}/lineItems/${lineItemId}`,
      {},
    );

    return res.data;
  }

  /** =============================
   *  GET-ROUTES
   *  =============================
   */

  export const useShipmentsByOrder = (
    orderId: number | undefined,
    orderType: OrderType,
  ): IUseArrayResponse<ShipmentDto> => {
    let url: string | null = null;

    if (orderId) {
      if (orderType === OrderType.ORDER) {
        url = `${backendUri}/order/${orderId}/shipments`;
      } else if (orderType === OrderType.RETOURE) {
        url = `${backendUri}/order/${orderId}/retoures`;
      }
    }

    const { data, error, mutate } = useSWR<ShipmentDto[]>(url);

    return {
      data: data || [],
      error,
      isLoading: !!url && !error && typeof data === 'undefined',
      isError: !!error,
      mutate,
    };
  };

  export async function getShipmentsByOrder(orderId: number): Promise<ShipmentDto[]> {
    const res = await client.get(`${backendUri}/order/${orderId}/shipments`);
    return res.data;
  }

  export async function getRetoureShipmentsByOrder(orderId: number): Promise<ShipmentDto[]> {
    const res = await client.get(`${backendUri}/order/${orderId}/retoures`);
    return res.data;
  }

  export const useShipment = (shipmentId: number) => {
    const { data, error, isLoading, mutate } = api.useApi({ key: `/v1/shipments/{shipmentId}`, shipmentId });

    return {
      data: data ?? null,
      error,
      isLoading,
      isError: !!error,
      mutate,
    };
  };

  export function useShipments(
    query: GetShipmentsSearchQuery | null,
    shipmentType: ShipmentType = ShipmentType.RETOUR,
  ) {
    const { data, isLoading, mutate } = api.useApi(query ? '/v1/shipments/search' : null, {
      ...query,
      shipmentType,
    });

    return {
      data,
      isLoading,
      mutate,
    };
  }

  export async function getRetoureShipmentsCSV(query: GetRetoureShipmentsCSVQuery): Promise<string> {
    const res = await client.get(`${backendUri}/v1/shipments/retoures/csv`, {
      responseType: 'arraybuffer',
      params: {
        fromDate: query.fromDate,
        toDate: query.toDate,
        processIds: query.processIds.join(','),
        shipmentStates: query.shipmentStates?.join(','),
      },
    });

    return res.data;
  }

  export async function getShipmentsSummaryCSV(query: GetShipmentSummaryCSVQuery): Promise<string> {
    const res = await client.get(`${backendUri}/v1/shipments/summary/csv`, {
      responseType: 'arraybuffer',
      params: {
        fromDate: query.fromDate,
        toDate: query.toDate,
        processIds: query.processIds.join(','),
      },
    });

    return res.data;
  }

  export function useWeightsForShipmentsWithStatusLabelPrinted() {
    const url = `${backendUri}/v1/shipments/weight-label-printed`;
    const { data, error } = useSWR<IWeightByWarehouse[]>(url, {
      refreshInterval: 10000,
      revalidateIfStale: true,
      revalidateOnFocus: true,
    });

    return {
      data: data || [],
      error,
      isLoading: !!url && !error && typeof data === 'undefined',
      isError: !!error,
    };
  }

  export function useReturnShipmentCounter(): { value: string | undefined; isLoading: boolean } {
    const { data, isLoading } = api.useApi('/return-shipment-counter');

    return {
      value: data?.returnShipmentId,
      isLoading,
    };
  }

  /** =============================
   *  UPDATE-ROUTES
   *  =============================
   */

  export async function updateShipment(
    shipmentId: number,
    body: ShipmentUpdateDto,
    context: IActivityContext = {},
  ): Promise<ShipmentUpdateResponseDto> {
    const res = await client.put<ShipmentUpdateDto, AxiosResponse<ShipmentUpdateResponseDto>>(
      `${backendUri}/v1/shipments/${shipmentId}`,
      body,
      { headers: activityContextToHeader(context) },
    );

    return res.data;
  }

  export async function fixEmptyExportoBarcode(shipmentId: number): Promise<ShipmentUpdateResponseDto> {
    const res = await client.put<ShipmentUpdateResponseDto>(
      `${backendUri}/v1/shipments/${shipmentId}/fix-empty-exporto-barcode`,
      undefined,
    );

    return res.data;
  }

  /** =============================
   *  DELETE-ROUTES
   *  =============================
   */

  export async function deleteShipment(shipmentId: number): Promise<null> {
    const res = await client.delete(`${backendUri}/v1/shipments/${shipmentId}`);

    return res.data;
  }

  export async function removeLineItemFromShipment(shipmentId: number, lineItemId: number): Promise<null> {
    const res = await client.delete(`${backendUri}/v1/shipments/${shipmentId}/lineItems/${lineItemId}`);

    return res.data;
  }
  export const useBatchBarcodeSummary = (batchBarcode: string | null) => {
    const { data, error, isLoading, mutate } = useSWR<IBatchBarcodeSummaryDto>(
      batchBarcode ? `${backendUri}/v1/shipments/batch/${batchBarcode}/summary` : null,
    );

    return {
      data,
      error,
      isLoading,
      isError: !!error,
      mutate,
    };
  };

  export async function markBatchBarcodeAsProcessed(batchBarcode: string): Promise<null> {
    const res = await client.post(`${backendUri}/v1/shipments/batch/${batchBarcode}/processed`);

    return res.data;
  }
}
